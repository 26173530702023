.console {
	position: relative;
	padding: 3px;
	background-color: rgba(50,50,50,1);
	color: rgba(240,240,240,1);
	font-family: Consolas, "Liberation Mono", Courier, monospace;
	font-size: 0.9em;
	border: 5px solid rgba(210,210,210,1);
	margin: 0 0 0 0;
}

.console::after {
	clear: both;
	content: '';
}

.console_info_bar {
	margin: 0;
	padding: 0.2em 15px 0.2em 0.2em;
	background-color: rgba(5,5,5,1);
}

.console_log_container {
	height: 25em;
	overflow: auto;
	overflow-y: scroll;
}

.console_log {
	padding: 0 0.8em;
}

.console_error {
	color: rgba(255, 160, 160, 1);
}

.console_prompt {
	clear: both;
	background-color: rgba(255,255,255, 1);
	color: rgba(30, 30, 30,1);
	position: relative;
}

.console_prompt::before {
	position: absolute;
	left:0;
	right: 0;
	top: 0;
	bottom: 0;
	content: '$ ';
	line-height: 1.5em;
	padding: 0.5em;
}

.console_input {
	background-color: transparent;
	border: none;
	font-family: inherit;
	color: inherit;
	outline: none;

	line-height: 1.5em;
	min-height: 1.5em;
	padding: 0.5em 0.5em 0.5em 1.5em;
	position: relative;
	z-index: 10;
}

[data-before]::before {
	content: attr(data-before);
	color: inherit;
	background: inherit;
	text-decoration: underline;
	opacity: 0.1;
}

.console_input_background {
	line-height: 1.5em;
	min-height: 1.5em;
	padding: 0.5em 0.5em 0.5em 1.5em;
	background-color: transparent;
	border: none;
	font-family: inherit;
	color: inherit;
	outline: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	color: inherit;
	opacity: 0.7;
	z-index: 0;
}

.console_input * {
	background-color: transparent !important;
	color: inherit !important;
}

.console_log_input {
	margin: 0.5em 0 0 0;
	padding: 0.4em 0.5em;
	background-color: rgba(0,0,0,0.4);
	border-color: rgba(240,240,240,0.3);
	border-width: 1px 1px 1px 1px;
	border-style: solid;
	line-height: 1.3;

	cursor: pointer;
	border-radius: 0.3em 0.3em 0 0;
}

.console_log_input:hover {
	background-color: rgba(0,0,0,0.7);
}

.console_log_input:before {
	content: "> ";
}

.console_log_output {
	white-space: pre-wrap;
	margin: 0;
	margin: 0 0 0.5em 0;
	padding: 1em 0.5em 2em 2em;
	background-color: rgba(60,60,60,0.4);
	border-color: rgba(240,240,240,0.3);
	border-width: 0 1px 1px 1px;
	border-style: solid;
	line-height: 1;
	border-radius: 0 0 0.3em 0.3em;
}

.console_command {
	background-color: rgba(1,1,1,0.3);
	padding: 3px;
	border: 1px solid transparent;
	cursor: pointer;
}

.console_command:hover {
	border: 1px solid rgba(140,140,140,0.6);
}

.console_suggestion_head {
	font-weight: bold;
	margin: 0;
	padding: 0.5em;
	color: rgba(40,40,40,1);
	background-color: rgba(255,255,255,1);
	font-size: 1em;
	font-family: sans-serif;
	border-bottom: 2px solid #777;
}

.match {
	font-weight: bold;
	background-color: rgba(261, 223, 15, 0.8);
	border-radius: 0.3em;
	padding: 0.2em;
	box-shadow: 1px 1px 3px rgba(40,40,40,0.4);
}

.console_suggestions {
	position: absolute;
	bottom: 2.7em;
	left: 0;
	right: 0;
	z-index: 100;
	overflow: hidden;
	border: 3px solid rgba(50,50,50,1);
}

.console_suggestions ul {
	margin: 0; padding: 0;

	background-color: rgba(250,250,250, 1);
	color: rgba(5,5,5,1);
	max-height: 10em;
	overflow-y: auto;
}

.console_suggestions li {
	list-style: none;
	line-height: 1.5;
	padding: 0.3em 3em 0.3em 0.5em;
	cursor: pointer;
}

.console_suggestions li:hover {
	background-color: rgba(80,80,80,0.1);
}

.console_suggestions .active {
	background-color: rgba(50,50,50,0.1);
	font-weight: bold;
}

.console_env_info {
	color: rgba(200,200,200,0.7);
	float: right;
}
