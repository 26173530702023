body{
    background-color: #EEEEEE;
}

.datepicker{
    z-index:9999 !important;
}

p{

}

/*-----------------------------------------*/
/*                Tableaux 		   */
/*-----------------------------------------*/
.table{
    border: 1px solid #ccc;
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td{
    border: none !important;
}

.icone-action-tableau{
    font-size: 20px;
    padding: 0px 3px;
    color: #777
}

.icone-action-tableau:hover{
    color: #42a940;
}

/*-----------------------------------------*/
/*                Boutons 		   */
/*-----------------------------------------*/
.btn-success{
    background-color: #468282 !important;
    border-color: #195757 !important;
}

.btn-success:hover{
    color: #eee !important;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

/*-----------------------------------------*/
/*                Icones 		   */
/*-----------------------------------------*/
.fa-big{
    font-size: 16px;
}

/*-----------------------------------------*/
/*              Navbar haute		   */
/*-----------------------------------------*/
.titre_barre_haut{
    color: #fff;
    text-decoration: none;
    float: left;
    height: 50px;
    padding: 15px 12px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    transition: all 0.5s ease;
}

.menu-user-haut a {
    font-size: 15px;
    color: #eee;
    transition: all 0.3s ease;
    text-decoration: none;
}

.navbar-inverse .navbar-nav>li>a{
    color: #eee;
}

.navbar-inverse .navbar-nav>.open>a{
    background-color: #333 !important;
}

@media (max-width:991px){
    .menu-user-haut{
        font-size: 12px;
        color: #eee;
        transition: all 0.3s ease;
    }
    
    .navbar-inverse .navbar-nav>li>a:hover{
        font-size: 13px;
    }
}

/*-----------------------------------------*/
/*                 Accueil  		   */
/*-----------------------------------------*/
.input-group-lg>.form-control{
    height: 45px !important;
}

/*-----------------------------------------*/
/*               Catégorie  		   */
/*-----------------------------------------*/
.titre-categ-niv-1{
    font-size: 20px !important;
}
/*-----------------------------------------*/
/*                 Page  		   */
/*-----------------------------------------*/
.chemin-categorie{
    padding-left: 30px;
    padding-bottom: 15px;
    font-size: 18px !important;
    font-weight: 300;
}

.titre-page{
    font-size: 26px !important;
    margin-top: 20px;
    font-weight: 300;
}

.mots-cles{
    font-size: 20px !important;
    font-weight: 500;
}

.label-vert-concept{
    font-size : 16px;
    background-color : #468282;
    font-weight: 400;
}

.titre-bloc-page{
    font-size: 24px !important;
    margin-top: 20px;
    font-weight: 300;
}

.liste-page{
    display: block;
    font-size: 18px;
    text-decoration: none;
    color: #12615a;
    padding: 5px 10px;
    font-weight: 300;
}

.liste-page:hover{
    background-color: #12615a;
    color: #fff;
}

.source-traitement>p{
    font-family: Courier;
    -webkit-margin-before: 0em !important;
    -webkit-margin-after: 0em !important;
    margin: 0px 0px !important;
}

/*-----------------------------------------*/
/*                  Login  		   */
/*-----------------------------------------*/
.login-logo{
    color: #12615a;
    background-color: #fff;
    font-size: 26px;
    margin-bottom: 0px !important;
    padding-top: 15px;
}

.se-souvenir-de-moi{
    padding-right: 5px;
    font-weight : 500 !important;
}

/*-----------------------------------------*/
/*                Couleurs		   */
/*-----------------------------------------*/
.bleu{
    color: #08C !important;
}

.rose{
    color: #c7254e !important;
}

.vert{
    color: #42a940 !important;
}

.vert-concept{
    color: #12615a !important;
}

.rouge{
    color: #ac2925 !important;
}

.blanc{
    color: #f5f5f5 !important;
}

.gris{
    color: #666 !important;
}

.back-gris{
    background-color: #E6E6E6 !important;
}

.back-gris-clair{
    background-color: #ecf0f5 !important;
}

.back-orange{
    background-color: #f28e0c !important;
    color: #fff;
}

.back-vert{
    background-color: #42a940 !important;
    color: #fff;
}

.back-vert-concept{
    background-color: #12615a !important;
    color: #fff;
}

.back-violet{
    background-color: #9300ca !important;
    color: #fff;
}

.opacite-40{
    background: rgba(255, 255, 255, 0.4);
}

.opacite-100{
    background: rgba(255, 255, 255, 1);
}

/*-----------------------------------------*/
/*                  Divers		   */
/*-----------------------------------------*/
.marge-droite-5{
    margin-right: 5px;
}

.marge-droite-10{
    margin-right: 10px;
}

.marge-droite-20{
    margin-right: 20px;
}

.marge-haute-5{
    margin-top: 5px;
}

.marge-haute-10{
    margin-top: 10px;
}

.marge-haute-15{
    margin-top: 15px;
}

.marge-haute-30{
    margin-top: 30px;
}

.marge-basse-20{
    margin-bottom: 20px;
}

.marge-gauche-5{
    margin-left: 5px;
}

.marge-gauche-10{
    margin-left: 10px;
}

.marge-gauche-20{
    margin-left: 20px;
}

.marge-gauche--20{
    margin-left: -20px;
}

.padding-droite-20{
    padding-right: 20px;
}

.padding-gauche-20{
    padding-left: 20px;
}

.padding-bas-20{
    padding-bottom: 20px;
}

.img-centree{
    margin-left: auto;
    margin-right: auto;
}

.vertical-center {
    display: inline-block;
    vertical-align: middle;
    float: none;
}