        .onoffswitch {
        position: relative; width: 40px;
        -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
    }
    .onoffswitch-checkbox {
        display: none;
    }
    .onoffswitch-label {
        display: block; overflow: hidden; cursor: pointer;
        border: 1px solid #ccc; border-radius: 15px;
    }
    .onoffswitch-inner {
        display: block; width: 200%; margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
    }
    .onoffswitch-inner:before, .onoffswitch-inner:after {
        display: block; float: left; width: 50%; height: 17px; padding: 0; line-height: 17px;
        font-size: 10px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
        box-sizing: border-box;
    }
    .onoffswitch-inner:before {
        content: "";
        padding-left: 8px;
        background-color: #468282; color: #FFFFFF;
    }
    .onoffswitch-inner:after {
        content: "";
        padding-right: 8px;
        background-color: #EEEEEE; color: #8A8A8A;
        text-align: right;
    }
    .onoffswitch-switch {
        display: block; width: 16px; margin: 1px;
        background: #FFFFFF;
        position: absolute; top: 0; bottom: 0;
        right: 20px;
        border: 1px solid #999999; border-radius: 14px;
        transition: all 0.3s ease-in 0s;
		box-sizing: content-box;
    }
    .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
        margin-left: 0;
    }
    .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
        right: 0px; 
    }
    
    .label_bouton_switch{
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 400;
    }