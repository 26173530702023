/*@import url(http://fonts.googleapis.com/css?family=Roboto:400,500,700,300,100);*/

/*** Table Styles **/
.table-design {
  background: white;
  border-radius:3px;
  border-collapse: collapse;
  padding:5px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation: float 5s infinite;
}
 
.table-design>tbody>tr>th {
  color:#D5DDE5;;
  background:#1b1e24;
  border-bottom:4px solid #9ea7af;
  border-right: 1px solid #343a45;
  font-size:20px;
  font-weight: 100;
  padding:8px 16px;
  text-align:left;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align:middle;
}

.table-design>tbody>tr>th:first-child {
  border-top-left-radius:3px;
}
 
.table-design>tbody>tr>th:last-child {
  border-top-right-radius:3px;
  border-right:none;
}
  
.table-design>tbody>tr {
  border-top: 1px solid #C1C3D1;
  border-bottom: 1px solid #C1C3D1;
  color:#666B85;
  font-size:16px;
  font-weight:normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}
 
.table-design>tbody>tr:first-child {
  border-top:none;
}

.table-design>tbody>tr:last-child {
  border-bottom:none;
}
 
.table-design>tbody>tr:nth-child(odd) .table-design>tbody>tr>td {
  background:#EBEBEB;
}
 
.table-design>tbody>tr:nth-child(odd):hover .table-design>tbody>tr>td {
  background:#4E5066;
}

.table-design>tbody>tr:last-child .table-design>tbody>tr>td:first-child {
  border-bottom-left-radius:3px;
}
 
.table-design>tbody>tr:last-child .table-design>tbody>tr>td:last-child {
  border-bottom-right-radius:3px;
}
 
.table-design>tbody>tr>td {
  background:#FFFFFF;
  padding:8px;
  text-align:left;
  vertical-align:middle;
  font-weight:400;
  font-size:18px;
  border-right: 1px solid #C1C3D1;
}

.table-design>tbody>tr>td:last-child {
  border-right: 0px;
}

.table-design>tbody>th.text-left {
  text-align: left;
}

.table-design>tbody>th.text-center {
  text-align: center;
}

.table-design>tbody>th.text-right {
  text-align: right;
}

.table-design>tbody>tr>td.text-left {
  text-align: left;
}

.table-design>tbody>tr>td.text-center {
  text-align: center;
}

.table-design>tbody>tr>td.text-right {
  text-align: right;
}
